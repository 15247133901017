import { I18n } from "@lingui/core";

import availableLanguages from "assets/data/availableLanguages";

export interface Languages {
    defaultLanguageCode: string;
    supportedLanguageCodes: string[];
}

export interface LanguagesList {
    label: React.ReactNode;
    labelAsString: (i18n: I18n) => string | undefined;
    code: string;
}

export const getLanguageNamesbyCodes = (data: string[]): LanguagesList[] => {
    if (data && Array.isArray(data)) return data.map((code) => ({ code, label: getLanguageNamebyCode(code), labelAsString: (i18n: I18n) => getLanguageNamebyCodeAsString(code, i18n) }));

    return [];
};

export const getLanguageNamebyCode = (code: string) => {
    return availableLanguages.find((item: any) => item.code === code.toLowerCase())?.label;
};

export const getLanguageNamebyCodeAsString = (code: string, i18n: I18n) => {
    return availableLanguages.find((item: any) => item.code === code.toLowerCase())?.labelAsString(i18n);
};
